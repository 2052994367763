<template>
    <div class="light">
        <template v-if="!data">

            <b-card no-body class="shadow register ml-auto mr-auto">
                <b-card-header header-class="st-cardheader" style="text-align: center;">
                    <font-awesome-icon class="float-left mt-1" :icon="icon"/>
                    {{ toolName }} Registration
                </b-card-header>
                <b-card-body>
                    <div v-if="registerErrors && registerErrors.error" class="register-error">{{ registerErrors.error }}</div>
                    <br>
                    <b-form>
                        <b-input-group prepend="Email">
                            <b-form-input id="skellettool-email" type="text" ref="email" v-model="email"/>
                        </b-input-group>
                        <div v-if="registerErrors && registerErrors.email" class="register-error">{{ registerErrors.email[0] }}</div>
                        <br>
                        <b-input-group prepend="Password">
                            <b-form-input type="password" v-model="password"/>
                            <b-input-group-append is-text>
                                <font-awesome-icon :icon="['fas', 'shield-alt']"/>
                            </b-input-group-append>
                        </b-input-group>
                        <br>
                        <b-input-group prepend="Confirm Password">
                            <b-form-input type="password" v-model="password_confirmation"/>
                            <b-input-group-append is-text>
                                <font-awesome-icon :icon="['fas', 'shield-alt']"/>
                            </b-input-group-append>
                        </b-input-group>
                        <div v-if="registerErrors && registerErrors.password" class="register-error">{{ registerErrors.password[0] }}</div>
                    </b-form>
                </b-card-body>
                <b-card-footer footer-class="light" style="text-align: center;">
                    <b-button pill class="pl-5 pr-5" :disabled="!(email && password && password_confirmation)" v-on:click="register">Register</b-button>
                </b-card-footer>
            </b-card>

            <b-card no-body class="shadow register ml-auto mr-auto">
                <b-card-body>
                    <ul>
                        <li>Your password must contain minimum 10 characters</li>
                        <li>Your password must contain at least one uppercase letter (ex: A, B, etc.)</li>
                        <li>Your password must contain at least one lowercase letter (ex: a, b, etc.)</li>
                        <li>Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)</li>
                    </ul>
                </b-card-body>
            </b-card>

        </template>
        <template v-else>
            <div class="row justify-content-center align-items-center">
                <b-card no-body class="shadow register">
                    <b-card-header header-class="st-cardheader" style="text-align: center;">
                        Welcome {{ data.userName }}
                    </b-card-header>
                    <b-card-body>
                        <p>You have been registered successfully!</p>
                        <p>We will send you an email as soon as your account has been activated and you can start using the {{ toolName }}.</p>
                        <p>Regards,</p>
                        <p>{{ toolName }}</p>
                    </b-card-body>
                </b-card>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "Register",
    components: {},
    props: ['name', 'icon', 'api'],
    data() {
        return {
            toolName: process.env.VUE_APP_NAME,
            data: null,
            registerToken: this.$route.params.registerToken,
            email: null,
            password: null,
            password_confirmation: null,
            registerErrors: null,
        }
    },
    mounted() {
        this.$refs.email.focus();
    },
    methods: {
        register() {
            this.registerErrors = null;
            this.$http.post(this.api + '/register', {
                    'email': this.email,
                    'password': this.password,
                    'password_confirmation': this.password_confirmation,
                    'register_token': this.registerToken
                },
                {}
            ).then((res) => {
                this.data = res.data;
            }).catch((error) => {
                this.registerErrors = error.response.data.errors;
            });
        }
    }
}
</script>

<style scoped>
.register {
    width: 600px;
    margin-top: 35px;
    margin-bottom: 35px;
}

.register-error {
    color: red;
    text-align: center;
    margin-top: 5px;
}
</style>
